import React from 'react';

function Screen4() {
  return (
    <div className="screen">
      <div className="image-container">
        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGOo9_fQJ4SbxUTfMn_NusaRiXW9FNUI5gn0gObLS-Msmehh3deXBL4LEXBsdPG1734ow&usqp=CAU" alt="Screen 4" />
      </div>
      <h2>Free delivery offers</h2>
      <p>   Get your loved foods in one once place, <br />
      you just place the order we do the rest.
      </p>
    </div>
  );
}

export default Screen4;