import React, { useEffect, useState } from "react";
import NavBarBottom from "./NavBarBottom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faStar } from "@fortawesome/free-solid-svg-icons";
import PopularCompanies from "./PopularCompanies";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
  Spinner,
  CardImg,
  CardText,
} from "reactstrap";
import axios from "axios";
import NavBarTop from "./NavBarTop";
import MyDeals from "./deals/MyDeals";
import SignIn from "./auth/SignIn";
import DealList from "./deals/DealList";
import PopularDeals from "./deals/PopularDeals";
import { IoTimeOutline } from "react-icons/io5";
import { AiOutlineDollar } from "react-icons/ai";
import { TiStarOutline } from "react-icons/ti";
import { CiSearch } from "react-icons/ci";
import { IoChevronBackCircleSharp } from 'react-icons/io5';
import { FaUserCircle, FaUserLock } from 'react-icons/fa';

function Companies() {
  const [search, setSearch] = useState("");
  const [userid, setUserId] = useState("");

  const [companies, setCompanies] = useState([
    {
      id: 2,
      name: "Hub Express",
      description: "Burgers-Italian-Sushi-Indian",
      image: "rest2.gif",
      rating: 4.7,
      priceRange: 3,
      deliveryTime: "20 min",
    },
    {
      id: 1,
      name: "The Hub 7",
      description: "JLT, Dubai",
      image: "rest1.gif",
      rating: 4.7,
      priceRange: 3,
      deliveryTime: "20 min",
    },
    {
      id: 3,
      name: "Lucca Stoppony",
      description: "Al Fahidi, Bur Dubai",
      image: "lucca.jpg",
      rating: 4.7,
      priceRange: 3,
      deliveryTime: "20 min",
    },
  ]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);



  
  useEffect(() => {
    const user_id = localStorage.getItem("user_id");

    if (user_id) {
      setUserId(user_id);
    }
    
  }, []);


  useEffect(() => {
    filterCompanies();
  }, [search]);

  const filterCompanies = () => {
    const filtered = companies.filter(
      (company) =>
        company.name.toLowerCase().includes(search.toLowerCase()) ||
        company.description.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredCompanies(filtered);
  };

  const CompanyCard = ({ company }) => (
    <Col
      xs="12"
      style={{ width: "100%", maxWidth: "400px", marginBottom: "20px" }}
    >
      <Link
        style={{ textDecoration: "none" }}
        to={`/companydeals/${company.id}`}
      >
        <div style={{ width: "100%", borderRadius: "8px", overflow: "hidden" }}>
          <img
            src={company.image}
            alt={`${company.name} Image`}
            style={{ width: "100%", height: "150px", objectFit: "cover" }}
          />
          <div style={{ marginTop: "10px" }}>
            <h5 className="heading" style={{ margin: "0 0 10px 0" }}>
              {company.name}
            </h5>
            <p className="pc-card-text" style={{ margin: 0 }}>
              {company.description}
            </p>
          </div>
          <div
            className="d-flex align-items-center gap-4"
            style={{ marginTop: "10px" }}
          >
            <div className="d-flex align-items-center">
              <TiStarOutline color="#00A3FF" size={30} />
              <span style={{ marginLeft: "2px", color: "black" }}>
                {company.rating}
              </span>
            </div>
            <div className="d-flex align-items-center">
              <AiOutlineDollar color="#00A3FF" size={30} />
              <span style={{ marginLeft: "2px", color: "black" }}>
                {company.priceRange}
              </span>
            </div>
            <div className="d-flex align-items-center">
              <IoTimeOutline color="#00A3FF" size={30} />
              <span style={{ marginLeft: "2px", color: "black" }}>
                {company.deliveryTime}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </Col>
  );

  return (
    <>
      <div className="search-container">
      <div className="search-container" 
    style={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 0px 0px',
    marginBottom: '10px',
    backgroundColor: 'transparent',
}}>
    <div style={{
        display: 'flex',
        alignItems: 'center',
    }}>
        <Link to="/home" style={{
            color: '#000',
            textDecoration: 'none',
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '15px',
            marginLeft: '-13px'
        }}>
            <IoChevronBackCircleSharp color="gray" size={35} />
        </Link>
        <h2 style={{ margin: 0, fontSize: '22px' }}>Search</h2>
    </div>
   
    <div style={{
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        <a href="#/profile" style={{ color: "#000", textDecoration: "none" }}>
        {userid ? (
            <FaUserCircle size={30} color="gray" />
        ) : (
            <FaUserLock size={30} color="gray" />
        )}
        </a>
    </div>
</div>

        <div
          style={{
            position: "relative",
            width: "calc(100% - 40px)",
            paddingTop: "5px",
            margin: "0 auto",
            maxWidth: "800px",
            marginBottom: "10px",
          }}
        >
          <input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{
              width: "100%",
              height: "3rem",
              padding: "20px 30px 20px 30px",
              border: "1px solid #ddd",
              borderRadius: "5px",
              fontSize: "16px",
              outline: "none",
              backgroundColor: "#F5F5F5",
            }}
          />
          <CiSearch
            style={{
              position: "absolute",
              left: "10px",
              top: "55%",
              marginRight: "5px",
              transform: "translateY(-50%)",
              color: "#888",
              fontSize: "21px",
              pointerEvents: "none",
            }}
          />
        </div>
        {search === "" && (
          <div className="recent-keywords">
            <h3>Recent Keywords</h3>
            <div className="keyword-list">
              <span className="keyword">Burger</span>
              <span className="keyword">Sandwich</span>
              <span className="keyword">Pizza</span>
            </div>
          </div>
        )}

        <Row>
          <div
            style={{
              height: "auto",
              display: "flex",
              gap: "40px",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "95%",
              padding: "20px 20px",
              boxSizing: "border-box",
              paddingLeft: "20px",
              marginLeft: "10px",
            }}
          >
            {filteredCompanies.length > 0 ? (
              filteredCompanies.map((company) => (
                <CompanyCard key={company.id} company={company} />
              ))
            ) : (
              <p>No results found matching your search.</p>
            )}
          </div>
        </Row>
      </div>
      <NavBarBottom />
    </>
  );
}

export default Companies;
