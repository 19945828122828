import React from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Custom red icon
const redIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

function MapEvents() {
  useMapEvents({
    click: (e) => {
      const { lat, lng } = e.latlng;
      const url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
      window.location.href = url; // This will navigate to Google Maps in the same tab
    },
  });
  return null;
}

function LocationMap({ latitude, longitude, title }) {
  const position = [latitude, longitude];

  return (
    <div style={{ padding: '20px' }}>
      <h2>{title }</h2>
      <div style={{ height: '30vh', width: '100%', borderRadius: '10px', overflow: 'hidden' }}>
        <MapContainer 
          center={position} 
          zoom={13} 
          style={{ height: '100%', width: '100%' }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={position} icon={redIcon} />
          <MapEvents />
        </MapContainer>
      </div>
    </div>
  );
}

export default LocationMap;