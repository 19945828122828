import React from 'react';
import authDesignImage from '../../authDesign.png'; 

const AuthDesign = ({ heading, subheading, style }) => {
  return (
    <div style={{ 
      backgroundColor: '#131222', 
      color: 'white', 
      padding: '20px', 
      paddingBottom: '50px',
      textAlign: 'center',
      position: 'relative', 
      overflow: 'hidden',   
      ...style
    }}>
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '280px',  
        height: '280px', 
        backgroundImage: `url(${authDesignImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top left',
        transform: 'rotate(-45deg) translate(-50%, -50%)', 
        transformOrigin: 'top left',
      }} />
      <h1 style={{marginTop:"3rem", position: 'relative', zIndex: 1}}>{heading}</h1>
      <p style={{position: 'relative', zIndex: 1}}>{subheading}</p>
    </div>
  );
};

export default AuthDesign;