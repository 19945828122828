import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import AuthDesign from "./AuthDesign";
import NavBarBottom from "../NavBarBottom";

function SignUp({ onSignup }) {
   

    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const initialValues = {
        email: queryParams.get('email') || '',
        password: queryParams.get('password') || '',
    };
    

    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [mobile, setMobile] = useState("");
    const [bday, setBDay] = useState("");
    const [email, setEmail] = useState(initialValues.email);
    const [password, setPassword] = useState(initialValues.password);

    const [passwordShown, setPasswordShown] = useState(false);
    const [validationError, setValidationError] = useState({});

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    useEffect(() => {
        setEmail(initialValues.email);
        setPassword(initialValues.password);
    }, [initialValues.email, initialValues.password]);

    const createCustomer = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('first_name', first_name);
        formData.append('last_name', last_name);
        formData.append('mobile', mobile);
        formData.append('bday', bday);
        formData.append('email', email);
        formData.append('password', password);

        try {
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/public/api/customers`, formData);
            const { user_id } = data.customer;
            localStorage.setItem('user_logged_in', true);
            localStorage.setItem('user_id', user_id);

            if (onSignup) {
                onSignup();
                
            }
            navigate('/home');
        } catch (response) {
            if (response.status === 422) {
                setValidationError(response.data.errors);
            } else {
                console.log(response);
            }
        }
    };

    return (
        <div style={{ backgroundColor: '#f5f5f5', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <AuthDesign
          style={{ 
            flexGrow: 1,
            marginBottom: '-30px',  
            paddingBottom: '30px',  
            flexShrink: 0
          }}
          heading="Sign Up"
          subheading="Please Sign Up to get started"
        />
        <Container fluid className="p-0 d-flex flex-column" style={{
          backgroundColor: 'white',
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          flexGrow: 2,
          overflow: 'auto',
          position: 'relative',  
          zIndex: 1,  
        }}>
          <div className="p-4 flex-grow-1">
            <Form onSubmit={createCustomer}>
              <Row>
                <Col xs={6} md={6}>
                  <FormGroup>
                    <Label for="firstName">FIRST NAME</Label>
                    <Input
                      id="firstName"
                      name="firstName"
                      placeholder="Jhon"
                      required
                      value={first_name}
                      type="text"
                      onChange={(event) => setFirstName(event.target.value)}
                      style={{
                        height: '50px',  
                        padding: '12px 16px',  
                        fontSize: '15px',  
                        borderRadius: '6px',  
                        border: '1px solid #ced4da',  
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',  
                        transition: 'box-shadow 0.3s ease-in-out', 
                        backgroundColor:'#f0f5f9',  

                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={6} md={6}>
                  <FormGroup>
                    <Label for="lastName">LAST NAME</Label>
                    <Input
                      id="lastName"
                      name="lastName"
                      placeholder="doe"
                      required
                      type="text"
                      value={last_name}
                      onChange={(event) => setLastName(event.target.value)}
                      style={{
                        height: '50px',  
                        padding: '12px 16px',  
                        fontSize: '15px',  
                        borderRadius: '6px',  
                        border: '1px solid #ced4da',  
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',  
                        transition: 'box-shadow 0.3s ease-in-out',  
                        backgroundColor:'#f0f5f9',  

                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={6}>
                  <FormGroup>
                    <Label for="mobile">MOBILE NO</Label>
                    <Input
                      id="mobile"
                      name="mobile"
                      placeholder=""
                      type="text"
                      required
                      value={mobile}
                      onChange={(event) => setMobile(event.target.value)}
                      style={{
                        height: '50px',  
                        padding: '12px 16px',  
                        fontSize: '15px',  
                        borderRadius: '6px',  
                        border: '1px solid #ced4da',  
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',  
                        transition: 'box-shadow 0.3s ease-in-out', 
                       backgroundColor:'#f0f5f9',  

                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={6} md={6}>
                  <FormGroup>
                    <Label for="birthday">BIRTHDAY</Label>
                    <Input
                      id="birthday"
                      name="birthday"
                      placeholder=""
                      type="date"
                      required
                      value={bday}
                      onChange={(event) => setBDay(event.target.value)}
                      style={{
                        height: '50px',  
                        padding: '12px 16px',  
                        fontSize: '15px',  
                        borderRadius: '6px',  
                        border: '1px solid #ced4da',  
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',  
                        transition: 'box-shadow 0.3s ease-in-out', 
                        backgroundColor:'#f0f5f9',  

                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label for="email">EMAIL</Label>
                <Input
                  id="email"
                  name="email"
                  required
                  placeholder="example@gmail.com"
                  type="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  style={{
                    height: '50px',  
                    padding: '12px 16px',  
                    fontSize: '15px',  
                    borderRadius: '6px',  
                    border: '1px solid #ced4da',  
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',  
                    transition: 'box-shadow 0.3s ease-in-out', 
                    backgroundColor:'#f0f5f9',  

                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="password">PASSWORD</Label>
                <div className="position-relative">
                  <Input
                    id="password"
                    name="password"
                    required
                    placeholder="••••••"
                    type={passwordShown ? "text" : "password"}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    style={{
                        height: '50px',  
                        padding: '12px 16px',  
                        fontSize: '15px',  
                        borderRadius: '6px',  
                        border: '1px solid #ced4da',  
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',  
                        transition: 'box-shadow 0.3s ease-in-out',  
                      backgroundColor:'#f0f5f9',  

                      }}
                  />
                  <Button
                    type="button"
                    className="position-absolute end-0 top-50 translate-middle-y bg-transparent border-0 text-primary"
                    onClick={togglePassword}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </Button>
                </div>
              </FormGroup>
              <Button 
                style={{
                  backgroundColor:"#00A3FF", 
                  color:"white", 
                  marginTop:"10px", 
                  height: '50px',
                  padding: '12px 16px'
                }}  
                className="w-100 btn-primary" 
                type="submit"
              >
                SIGN UP
              </Button>
            </Form>
          </div>
          <div className="text-center mb-4">
            <p>Already have an account? <a style={{color:"#00A3FF", fontWeight:"bold"}} href="#/signin" className=" text-decoration-none">SIGN IN</a></p>
          </div>
        </Container>
        <NavBarBottom />
      </div>
    );
}

export default SignUp;
