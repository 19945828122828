import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

function VerificationModal({ show, onHide, onVerify }) {
  const [code, setCode] = useState(['', '', '', '']);

  const handleCodeChange = (index, value) => {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
    if (value && index < 3) {
      document.getElementById(`code-${index + 1}`).focus();
    }
  };

  const handleVerify = () => {
    onVerify(code.join(''));
  };

  return (
    <Modal show={show} onHide={onHide} centered size="sm">
      <div style={{ position: 'relative' }}>
        <button 
          onClick={onHide} 
          style={{
            position: 'absolute',
            top: '-10px',
            right: '-10px',
            background: 'none',
            border: 'none',
            fontSize: '18px',
            cursor: 'pointer',
            zIndex: 1
          }}
        >
          ×
        </button>
        <Modal.Header style={{ border: 'none', paddingBottom: 0, paddingTop: '20px' }}>
          <Modal.Title style={{ fontSize: '16px' }}>Please Enter PIN to verify</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: 0, paddingBottom: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '15px', marginTop: '20px' }}>
            {code.map((digit, index) => (
              <input
                key={index}
                id={`code-${index}`}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleCodeChange(index, e.target.value)}
                style={{
                  width: '35px',
                  height: '35px',
                  borderRadius: '5px',
                  textAlign: 'center',
                  fontSize: '20px',
                  backgroundColor: '#f0f0f0',
                  border: '1px solid #ccc',
                  marginBottom: '15px'
                }}
              />
            ))}
          </div>
          <Button
            onClick={handleVerify}
            style={{
              width: '100%',
              padding: '5px 0',
              fontSize: '16px',
              backgroundColor: '#00A3FF'
            }}
          >
            Verify
          </Button>
        </Modal.Body>
      </div>
    </Modal>
  );
}

export default VerificationModal;