import React, { useEffect, useState } from "react";
import { Card, CardHeader, Col, Container, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import axios from 'axios';
import NavBarBottom from "./NavBarBottom";
import NavBarTop from "./NavBarTop";
import { useNavigate } from "react-router-dom";


function Menu() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const mainUrl = process.env.REACT_APP_MAIN_URL;
    const dineInAppUrl = process.env.REACT_APP_DINE_IN_APP_URL;

    //console.log(`this is dine in app url : ${dineInAppUrl}`);

    const [loading, setLoading] = useState(true);
    console.log(dineInAppUrl);
    const hideSpinner = () => {
        setLoading(false);
    };

    return (
        <>
            <Container className="bg-light" fluid>
                {
                  loading ? <Spinner animation="border" /> : null
                }
                <iframe onLoad = {hideSpinner} style={{ width: "100%", height: "1000px" }} 
                src={`${dineInAppUrl}/site/restaurant/the-hub-7/1`}>
                    
                </iframe>
                <NavBarBottom />
            </Container>
        </>
    )
}

export default Menu;