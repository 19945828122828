import React, { useEffect, useState } from 'react';
import { Button, FormGroup, Input, Form } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles.css'

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [token, setToken] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        console.log("Full URL:", window.location.href);
        
        // Try to get token from hash
        const hash = location.hash.substring(1);
        let searchParams = new URLSearchParams(hash);
        let tokenFromUrl = searchParams.get('token');
    
        // If not found in hash, try to get from search params
        if (!tokenFromUrl) {
            searchParams = new URLSearchParams(location.search);
            tokenFromUrl = searchParams.get('token');
        }
    
        if (tokenFromUrl) {
            setToken(tokenFromUrl);
            console.log("Token set:", tokenFromUrl);
        } else {
            console.log("No token found in URL");
            // You might want to show an error message to the user here
            Swal.fire({
                icon: 'error',
                title: 'Invalid Reset Link',
                text: 'The password reset link appears to be invalid. Please request a new one.',
            });
        }
    }, [location]);


    const changePassword = async (e) => {
        e.preventDefault();
    
        console.log("Current token state:", token);
    
        if (!token) {
            console.log("No token available, cannot send request");
            Swal.fire({
                icon: 'error',
                title: 'Invalid Token',
                text: 'No reset token found. Please try requesting a new password reset link.',
            });
            return;
        }
    
        if (password !== confirmPassword) {
            Swal.fire({
                icon: 'error',
                title: 'Passwords do not match!',
                text: 'Please check your passwords and try again.',
            });
            return;
        }
    
        try {
            console.log("Sending request with token:", token);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/public/api/reset-password`, {
                token: token,
                password: password,
            }, {
                withCredentials: true
            });
    
            console.log("Response:", response.data);
    
            Swal.fire({
                icon: 'success',
                title: 'Password Reset Successful!',
                text: 'Your password has been updated successfully.',
            }).then((result) => {
                if (result.isConfirmed) {
                    // Redirect to login page
                    navigate('/signin');
                }
            });
    
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error);
            Swal.fire({
                icon: 'error',
                title: 'Password Reset Failed!',
                text: error.response && error.response.data.message 
                    ? error.response.data.message 
                    : 'Unable to reset your password. Please try again later.',
            });
        }
    };

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 10px',
        minHeight: '100vh',
    };

    const formStyle = {
        width: '100%',
        maxWidth: '400px',
        background: '#fff',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    };

    return (
        <div style={containerStyle}>
            <div style={formStyle}>
                <h2>Reset Password</h2>
                <Form onSubmit={changePassword}>
                    <FormGroup>
                        <Input
                            type="password"
                            name="password"
                            id="password"
                            placeholder="New Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </FormGroup>
                    <Button type="submit" color="primary" className="border-radius-15 gr">
                        Change Password
                    </Button>
                </Form>
            </div>
        </div>
    );
};
    

export default ResetPassword;