// App.js
import React, { useState, useEffect } from "react";
import { Routes, Route,Navigate, useNavigate, useLocation  } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './styles.css'

import Home from "./components/Home";
import Companies from "./components/Companies";
import CompanieDeals from "./components/deals/CompanyDeals";
import Verify from "./components/auth/Verify";
import CompanieDealCat from "./components/deals/CompanyDealCat";
import CompanyDealCatInfo from "./components/deals/CompanyDealCatInfo";
import DealInfo from "./components/deals/DealInfo";
import DealRedeem from "./components/deals/DealRedeem";
import DealHistory from "./components/deals/DealHistory";
import SignUp from "./components/auth/SignUp";
import SignIn from "./components/auth/SignIn";
import Profile from "./components/Profile";
import Menu from "./components/Menu";
import ForgetPassword from "./components/auth/ForgetPassword";
import ResetPassword from "./components/ResetPassword";
import Splash from "./components/Splash";
import OnboardingScreen from "./components/OnboardingScreen";

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      const timer = setTimeout(() => {
        navigate('/onboard');
      }, 2000); 
      return () => clearTimeout(timer);
    }
  }, [location, navigate]);

  return (
    <Routes>
      <Route path="/" element={<Splash />} />
      <Route path="/onboard" element={<OnboardingScreen />} />
      <Route path="/home" element={<Home />} />
      <Route path="/comp/:id" element={<Companies />} />
      <Route path="/companydeals/:id" element={<CompanieDeals />} />
      <Route path="/companydealcats/:id" element={<CompanieDealCat />} />
      <Route path="/dealcatinfo/:id" element={<CompanyDealCatInfo />} />
      <Route path="/dealinfo/:id" element={<DealInfo />} />
      <Route path="/dealredeem/:id" element={<DealRedeem />} />
      <Route path="/history" element={<DealHistory />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/menu" element={<Menu />} />
      <Route path="/reset" element={<ForgetPassword />} />
      <Route path="/verify" element={<Verify />} />
      <Route path="/resetpassword" element={<ResetPassword />} />



    </Routes>
  )
}



export default App;