import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardImg,
  CardText,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import axios from "axios";
import NavBarBottom from "./NavBarBottom";
import NavBarTop from "./NavBarTop";
import MyDeals from "./deals/MyDeals";
import SignIn from "./auth/SignIn";
import { Link, useNavigate } from "react-router-dom";
import DealList from "./deals/DealList";
import PopularDeals from "./deals/PopularDeals";
import PopularCompanies from "./PopularCompanies";
import Banner from "./Banner";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderContainer from "./Slider";
import CustomCard from "./Welcome";
import "normalize.css";
import SignupModal from "./SignupModal";
import InitialSignupModal from "./InitialSignupModal";
import { CiSearch } from "react-icons/ci";

function Home() {
  const [user, setUser] = useState();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cats, setCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isInitialModalOpen, setIsInitialModalOpen] = useState(false);
  const [isMainModalOpen, setIsMainModalOpen] = useState(false);
  const [initialSignupValues, setInitialSignupValues] = useState(null);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const userLoggedIn = localStorage.getItem("user_logged_in") === "true";
    setIsLoggedIn(userLoggedIn);
    if (!userLoggedIn) {
      setIsInitialModalOpen(true);
    }
  }, []);

  const toggleInitialModal = () => {
    setIsInitialModalOpen(!isInitialModalOpen);
  };

  const toggleMainModal = () => {
    setIsMainModalOpen(!isMainModalOpen);
  };

  const handleInitialSignup = (values) => {
    setInitialSignupValues(values);
    setIsInitialModalOpen(false);
    navigate("/signup");
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const apiUrl = process.env.REACT_APP_API_URL;
  const mainUrl = process.env.REACT_APP_MAIN_URL;
  console.log(`${process.env.REACT_APP_API_URL} and ${mainUrl}`);

  useEffect(() => {
    fetchProducts();
    fetchCat();

    const interval = setInterval(() => {
      const usname = localStorage.getItem("user_logged_in");
      if (usname) {
        setUser(usname);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const fetchProducts = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/public/api/deals`)
      .then(({ data }) => {
        setProducts(data);
        setLoading(true);
      })
      .catch(function (error) {});
  };

  const fetchCat = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/public/api/categories`)
      .then(({ data }) => {
        setCategories(data);
        setLoading(true);
      });
  };

  const handleSignupSuccess = () => {
    setIsMainModalOpen(false);
    setIsLoggedIn(true);
  };





  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          overflow: "hidden",
          touchAction: "none"
        }}
      >
        <NavBarTop
          style={{
            position: "fixed",
            top: '0',
            left: 0,
            right: 0,
            zIndex: 1000,
            paddingTop: "40px",
          }}
        />

        <div
          style={{
            marginTop: "40px",
            display: "flex",
            flexDirection: "column",
            height: "calc(100vh - 80px - 60px)",
            overflowY: "auto",
          }}
        >
          <Container fluid style={{ padding: 0 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                paddingTop: "20px",
              }}
            >
              <CustomCard title="custom message" />

              <div
                style={{
                  position: "relative",
                  width: "calc(100% - 40px)",
                  paddingTop: "5px",
                  margin: "0 auto",
                  maxWidth: "600px",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="text"
                  placeholder="Search dishes, restaurants"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "20px 30px 20px 30px",
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                    fontSize: "16px",
                    outline: "none",
                    backgroundColor: "#F5F5F5",
                  }}
                />
                <CiSearch
                  style={{
                    position: "absolute",
                    left: "10px",
                    top: "55%",
                    marginRight: "5px",
                    transform: "translateY(-50%)",
                    color: "#888",
                    fontSize: "21px",
                    pointerEvents: "none",
                  }}
                />
              </div>
            </div>
          </Container>

          <Container
            style={{ padding: 0, paddingTop: "20px", maxWidth: "100%" }}
          >
            <div
              className="row title"
              style={{
                display: "flex",
                justifyContent: "space-between",
                // alignItems: 'center',
                marginLeft: "10px",
                marginRight: "20px",
              }}
            >
              <div className="col">
                <p  style={{ fontSize: "18px",fontStyle:'normal' }}>All Categories</p>
              </div>
              <div className="col text-end">
                <Link
                  to={`/comp/${cats.id}`}
                  style={{
                    textDecoration: "none",
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  See all &gt;
                </Link>
              </div>
            </div>

            {/* cat styles */}

            <div
              style={{
                overflowX: "auto",
                marginTop: "-30px",
                paddingLeft: "25px",
                paddingRight: "20px",
                WebkitOverflowScrolling: "touch",
              }}
            >
              <Row
                className="title mydeal-box"
                style={{
                  marginTop: "2rem",
                  flexWrap: "nowrap",
                  width: "max-content",
                }}
              >
                {loading ? (
                  cats.length > 0 &&
                  cats.map((row, key) => (
                    <Col style={{ padding: "5px", minWidth: "auto" }} key={key}>
                      <Link
                        style={{ textDecoration: "none" }}
                        to={`/comp/${row.id}`}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: row.isSelected
                              ? "#007bff"
                              : "#f0f0f0",
                            borderRadius: "50px",
                            padding: "5px 15px 5px 5px",
                            color: row.isSelected ? "white" : "black",
                            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                          }}
                        >
                          <div
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                              overflow: "hidden",
                              marginRight: "10px",
                              backgroundColor: "white",
                            }}
                          >
                            <img
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                              src={row.image}
                              alt={row.name}
                            />
                          </div>
                          <span style={{ fontSize: "12px" }}>{row.name}</span>
                        </div>
                      </Link>
                    </Col>
                  ))
                ) : (
                  <Spinner animation="border" />
                )}
              </Row>
            </div>
          </Container>

          <Container
            style={{ backgroundColor: "#fff", padding: 0, maxWidth: "100%" }}
          >
            <PopularCompanies searchTerm={searchTerm} />
          </Container>
        </div>

        <NavBarBottom
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
          }}
        />
      </div>
    </>
  );
}

export default Home;
