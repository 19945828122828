import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Badge, Button, Spinner, Row } from 'reactstrap';
import Swal from "sweetalert2";

function MyDeals() {


    const [deals, setDeals] = useState([]);
    const [dealApr, setDealApr] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const interval = setInterval(() => {
            fetchDeals()
            fetchDealApr()
        }, 1000);

        return () => clearInterval(interval);


    }, [])

    const fetchDeals = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/public/api/mydeals/${localStorage.getItem("customerid")}`).then(({ data }) => {
            setDeals(data)
            //console.log(data)
            setLoading(true);
        })
    }

    const fetchDealApr = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/public/api/mydealsapr/${localStorage.getItem("customerid")}`).then(({ data }) => {
            setDealApr(data)
            //console.log(data)
            setLoading(true);
        })
    }

    const remo = async (id) => {
        Swal.fire({
            title: "Delete Request",
            text: "Do you want to delete the request?",
            showCancelButton: true,
            confirmButtonText: 'Yes',
            confirmButtonColor: '#008000',
        }).then((result) => {

            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_API_URL}/public/api/customer_deals/${id}`).then(({ data }) => {

                }).catch(({ response: { data } }) => {

                })
            } else if (result.isDenied) {

            }
        })
    }

    return (
        <>
            {/* <div className="row title" style={{ marginTop: "auto" }}>
                <div className="col-12" style={{ marginTop: "10px" }}>
                    <h6>My Deals</h6>
                    <p style={{ color: "#CCC" }}>{deals.length > 0 || dealApr.length > 0 ? "" : "You don't have deals"}</p>
                </div>
            </div>
            {
                loading ?
                    dealApr.length > 0 && (
                        dealApr.map((row, key) => (
                            <div className="row shadow border-radius-15 mydeal-box" style={{ marginBottom: "20px" }}>
                                <div className="col-3">
                                    <img className="border-radius-15" style={{ width: "100%", backgroundColor: "#CCC", padding: "5px" }}
                                        alt="Sample"
                                        src={row.image}
                                    />
                                </div>
                                <div className="col-5">
                                    <h6 style={{ color: "#CCC" }}>{row.title}</h6>
                                    <a href="#" style={{ color: "red", textDecoration: "none" }} onClick={() => remo(row.id)} >Delete Request</a>
                                </div>
                                <div className="col-4 text-center">
                                    <h3 style={{ color: "#CCC", marginBottom: "0px" }}>{row.remain}</h3>
                                    <p className="border-radius-15 gr" style={{ color: "#FFF", fontSize: "12px", padding: "5px",boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)" }}>Waiting for Approval</p>
                                    <p style={{ color: "#666", fontSize: "10px", padding: "0" }}>Valid Till {row.expiry_date}</p>

                                </div>
                            </div>

                        ))) : ""
            }
            {
                loading ?
                    deals.length > 0 && (
                        deals.map((row, key) => (
                            <Link style={{ textDecoration: "none" }} to={`/dealredeem/${row.id}`}>
                                <div className="row shadow border-radius-15 mydeal-box" style={{ marginBottom: "20px" }}>
                                    <div className="col-3" style={{ margin: "0px", padding: "5px" }}>
                                        <img className="border-radius-15" style={{ width: "100%", backgroundColor: "#CCC", padding: "5px" }}
                                            alt="Sample"
                                            src={row.image}
                                        />
                                    </div>
                                    <div className="col-5">
                                        <h6 style={{ color: "#2B2B2B" }}>{row.title}</h6>
                                        <span style={{ color: "#666", fontSize: "12px", padding: "0" }}>
                                            <Badge className="gr-orange" color="warning">
                                                {row.res_name}
                                            </Badge>
                                        </span> <br />
                                        <span style={{ color: "#666", fontSize: "12px", padding: "0" }}>

                                            Valid Till {row.expiry_date}

                                        </span>
                                    </div>
                                    <div className="col-4 text-center">
                                        <div className="gr border-radius-15" style={{ margin: "0px", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)" }}>
                                            <span style={{ color: "#FFF", fontSize: "14px" }}>You Have</span>
                                            <h3 style={{ color: "#FFF", marginBottom: "0px" }}>{row.remain}</h3>
                                            <span style={{ color: "#FFF", fontSize: "14px" }}>Left</span>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))) : <Spinner animation="grow" />
            } */}

            <div className="row title" style={{ marginTop: "auto" }}>
                <div className="col-12" style={{ marginTop: "10px" }}>
                    <h6>My Deals</h6>
                    <p style={{ color: "#CCC" }}>{deals.length > 0 || dealApr.length > 0 ? "" : "You don't have deals"}</p>
                </div>
            </div>
            {
                loading ?
                    dealApr.length > 0 && (
                        dealApr.map((row, key) => (

                            <div className="row shadow border-radius-15 mydeal-box" style={{ marginBottom: "20px" }}>
                                <div className="col-3 d-flex align-items-center" style={{ padding: "5px", margin: "0px" }}>
                                    <div>
                                        <span style={{ color: "#666", fontSize: "12px", padding: "0" }}>
                                            <Badge className="gr-orange" color="warning">
                                                Restaurant Name
                                            </Badge>
                                        </span> <br />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div style={{ margin: "5px" }}>
                                        <h6 style={{ color: "#CCC", marginBottom: "0px" }}>{row.title}</h6>
                                        <span style={{ color: "#666", fontSize: "12px", padding: "0" }}>
                                            Valid Till {row.expiry_date}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-3 text-center d-flex align-items-center justify-content-center">
                                    <div className="border-radius-15" style={{ margin: "5px" }}>
                                        <p className="border-radius-15 gr" style={{ color: "#FFF", fontSize: "13px", padding: "5px", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)", marginBottom: "0px" }}>Waiting</p>
                                        <a href="#" style={{ color: "red", textDecoration: "none" }} onClick={() => remo(row.id)} >Delete Request</a>
                                    </div>
                                </div>
                            </div>
                        ))) : ""
            }
            {
                loading ?
                    deals.length > 0 && (
                        deals.map((row, key) => (
                            <Link style={{ textDecoration: "none" }} to={`/dealredeem/${row.id}`}>
                                <div className="row shadow border-radius-15 mydeal-box" style={{ marginBottom: "20px" }}>
                                    <div className="col-3 d-flex align-items-center" style={{ padding: "5px", margin: "0px" }}>
                                        <div>
                                            <span style={{ color: "#666", fontSize: "12px", padding: "0" }}>
                                                <Badge className="gr-orange" color="warning">
                                                    {row.res_name}
                                                </Badge>
                                            </span> <br />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div style={{ margin: "5px" }}>
                                            <h6 style={{ color: "#2B2B2B", marginBottom: "0px" }}>{row.title}</h6>
                                            <span style={{ color: "#666", fontSize: "12px", padding: "0" }}>
                                                Valid Till {row.expiry_date}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-3 text-center d-flex align-items-center justify-content-center">
                                        <div className="border-radius-15" style={{ margin: "5px" }}>
                                            <h3 style={{ color: "rgb(220, 53, 69)", marginBottom: "0px" }}>{row.remain}</h3>
                                            <span style={{ color: "#000", fontSize: "14px" }}>Remaining</span>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))) : <Spinner animation="grow" />
            }


        </>
    )
}
export default MyDeals;