import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import NavBarBottom from "../NavBarBottom";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import AuthDesign from "./AuthDesign";

function SignIn() {
    

  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [validationError, setValidationError] = useState({});

  // const login = async (e) => {
  //   e.preventDefault();

  //   const formData = new FormData();

  //   formData.append("email", email);
  //   formData.append("password", password);

  //   await axios
  //     .post(`${process.env.REACT_APP_API_URL}/public/api/login`, formData)
  //     .then(({ data }) => {
  //       //console.log(data.user)
  //       const { id, role } = data.user;

  //       if (role == "USER") {
  //         localStorage.setItem("user_logged_in", true);
  //         localStorage.setItem("user_id", id);
  //         //console.log(localStorage.getItem("user"))
  //         Swal.fire("Login Successful");
  //         navigate("/home");
  //       } else {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: "Invalid Email or Password !!",
  //         });
  //       }
  //       // navigate("/home")
  //     })
  //     .catch(({ response }) => {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "Invalid Email or Password !!",
  //       });
  //     });
  //   /* localStorage.setItem('user_logged_in', true)
  //        localStorage.setItem('email', email)
  //        navigate("/home")*/
  // };


  const login = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    try {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/public/api/login`, formData);
        
        const { id, role } = data.user;

        if (role === "USER") {
            localStorage.setItem("user_logged_in", "true");
            localStorage.setItem("user_id", id);
            Swal.fire("Login Successful");
            navigate("/home");
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Invalid user role!",
            });
        }
    } catch (error) {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response?.data?.errors?.message || "An error occurred during login",
        });
    }
};

  return (
    <>
     <div style={{ backgroundColor: '#f5f5f5', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <AuthDesign style={{ flexGrow: 1,
      marginBottom: '-30px',  
      paddingBottom: '30px',  
      flexShrink:0
       }}
        heading="Log In" 
        subheading="Please sign in to your existing account" 
      />
  <Container fluid className="p-0 d-flex flex-column" style={{ 
    backgroundColor: 'white', 
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    flexGrow: 2,
    overflow: 'auto',
    position: 'relative',  
    zIndex: 1,  
  }}>
  <div className="p-4 flex-grow-1">
    <Form onSubmit={login}>
      <FormGroup>
        <Label for="exampleEmail">EMAIL</Label>
        <Input
          id="exampleEmail"
          name="email"
          required
          placeholder="example@gmail.com"
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          style={{
            height: '55px',  
            padding: '12px 16px',  
            fontSize: '16px',  
            borderRadius: '8px',  
            border: '1px solid #ced4da',  
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)', 
            backgroundColor:'#f0f5f9', 
            transition: 'box-shadow 0.3s ease-in-out',  
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label for="examplePassword">PASSWORD</Label>
        <div className="position-relative">
          <Input
            id="examplePassword"
            name="password"
            required
            placeholder="••••••"
            type={passwordShown ? "text" : "password"}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            style={{
              height: '55px',  
              padding: '12px 16px',  
              fontSize: '16px',  
              borderRadius: '8px',  
              border: '1px solid #ced4da',  
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)', 
              backgroundColor:'#f0f5f9',  
              transition: 'box-shadow 0.3s ease-in-out',  
            }}
          />
          <Button 
            type="button" 
            className="position-absolute end-0 top-50 translate-middle-y bg-transparent border-0 text-primary"
            onClick={togglePassword}
          >
            <FontAwesomeIcon icon={faEye} />
          </Button>
        </div>
      </FormGroup>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <FormGroup check>
          <Label check>
            <Input type="checkbox" /> Remember me
          </Label>
        </FormGroup>
        <a href="#/reset" style={{color:"#00A3FF"}} className=" text-decoration-none">Forgot Password?</a>
      </div>
      <Button style = {{backgroundColor:"#00A3FF" , color:"white", marginTop:"30px", height: '55px',padding: '12px 16px'}}  className="w-100 btn-primary" type="submit">LOG IN</Button>
    </Form>
  </div>
  <div className="text-center  mb-4">
    <p>Don't have an account? <a style={{color:"#00A3FF", fontWeight:"bold"}} href="#/signup" className=" text-decoration-none">SIGN UP</a></p>
  </div>
</Container>
<NavBarBottom />
    </div>
    </>
  );
}
export default SignIn;
