import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import NavBarBottom from "../NavBarBottom";
import Swal from 'sweetalert2'
import AuthDesign from "./AuthDesign";

function ForgetPassword() {
    

    const navigate = useNavigate();

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
   

    const [validationError, setValidationError] = useState({})

    const login = async (e) => {

        e.preventDefault();

        const formData = new FormData()

        formData.append('email', email)
        formData.append('password', password)

        await axios.post(`${process.env.REACT_APP_API_URL}/public/api/forget`, formData).then(({ data }) => {
            console.log(data)
            //console.log(localStorage.getItem("user"))
            Swal.fire('Password Reset Request Sent Successful')
            //navigate("/home")
        }).catch(({ response }) => {
            console.log(response)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Invalid Email !!',
            })
        })
    }

    return (
        <>
            <div style={{ backgroundColor: '#f5f5f5', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
  <AuthDesign style={{
    flexGrow: 1,
    marginBottom: '-30px',  
    paddingBottom: '10px',  
    paddingTop:'50px',
    flexShrink: 0
  }}
    heading="Reset Password"
    subheading="Enter your email to reset your password"
  />
  <Container fluid className="p-0 d-flex flex-column" style={{ 
    backgroundColor: 'white', 
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    flexGrow: 2,
    overflow: 'auto',
    position: 'relative',  
    zIndex: 1,  
  }}>
    <div className="p-4 flex-grow-1">
      {/* <div className="text-center mb-4">
        <img style={{ width: "100px" }} src="../logo1.png" alt="Logo" />
      </div> */}
      <Form onSubmit={login}>
        <FormGroup>
          <Label for="exampleEmail">EMAIL</Label>
          <Input
            id="exampleEmail"
            name="email"
            required
            placeholder="example@gmail.com"
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            style={{
              height: '55px',  
              padding: '12px 16px',  
              fontSize: '16px',  
              borderRadius: '8px',  
              border: '1px solid #ced4da',  
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',  
              transition: 'box-shadow 0.3s ease-in-out',  
              backgroundColor:'#f0f5f9',  

            }}
          />
        </FormGroup>
        <Button 
          style={{
            backgroundColor:"#00A3FF", 
            color:"white", 
            marginTop:"30px", 
            height: '55px',
            padding: '12px 16px',
       

          }}  
          className="w-100 btn-primary" 
          type="submit"
        >
          SEND LINK
        </Button>
      </Form>
    </div>
    <div className="text-center mb-4">
      <p>Don't have an account? <a href="#/signup" className="text-primary text-decoration-none">SIGN UP</a></p>
    </div>
    <NavBarBottom />
  </Container>
</div>
        </>
    )
}
export default ForgetPassword;