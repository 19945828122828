import React from 'react';

function Screen3() {
  return (
    <div className="screen">
      <div className="image-container">
        <img src="https://www.frugalandthriving.com.au/wp-content/uploads/2017/08/simmering.jpg" alt="Screen 3" />
      </div>
      <h2>Order from choosen chef</h2>
      <p>  Get your loved foods in one once place, <br />
      you just place the order we do the rest.
      </p>
    </div>
  );
}

export default Screen3;