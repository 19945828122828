import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Button } from "reactstrap"; // Import Button from Reactstrap

const CustomCard = ({ title, content, width, height, style }) => {
  
  const [userid, setUserId] = useState("");
  const [greeting, setGreeting] = useState("");
  const [fname, setFirstName] = useState("");

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");

    if (user_id) {
      setUserId(user_id);
      fetchCustomer();
    }

    const today = new Date().getHours();

    if (today < 12) {
      setGreeting("Good Morning!");
    } else if (today < 16) {
      setGreeting("Good Afternoon!");
    } else if (today < 24) {
      setGreeting("Good Evening!");
    }
  }, []);

  const fetchCustomer = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/public/api/customerinfo/${localStorage.getItem("user_id")}`)
      .then(({ data }) => {
        const { id, first_name, last_name } = data.customer;
        setFirstName(first_name);
        localStorage.setItem("customerid", id);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  };

  const messageStyle = {
    fontSize: '18px',
    lineHeight: "32px",
    color: '#333',
    textAlign: 'left',
    marginLeft: '10px',
    padding: '15px 20px',
    ...style,
  };

  return (
    <div style={messageStyle}>
        {title && (
          <p  style={{ margin: 0 }}>
            Hey  {userid ? fname : "Guest"},  <span style={{fontWeight:'bold'}}>{greeting}</span>
          </p>
        )}
      
      
    </div>
  );
};

export default CustomCard;