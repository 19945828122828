import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Screen1 from './Screen1';
import Screen2 from './Screen2';
import Screen3 from './Screen3';
import Screen4 from './Screen4';

const screens = [Screen1, Screen2, Screen3, Screen4];

function OnboardingScreen() {
  const [currentScreen, setCurrentScreen] = useState(0);
  const navigate = useNavigate();

  const handleNext = () => {
    if (currentScreen < screens.length - 1) {
      setCurrentScreen(currentScreen + 1);
    } else {
      navigate('/home');
    }
  };

  const handleSkip = () => {
    navigate('/home');
  };

  const handleDotClick = (index) => {
    setCurrentScreen(index);
  };

  const CurrentScreenComponent = screens[currentScreen];
  const isLastScreen = currentScreen === screens.length - 1;

  return (
   <>
   <div className='onboarding-page'>
    <div className="onboarding-container">
      <CurrentScreenComponent />
      <div className="navigation-dots">
        {screens.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentScreen ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
      <div className="button-container">
        <button onClick={handleNext}>
          {currentScreen === screens.length - 1 ? 'Get Started' : 'Next'}
        </button>
        {!isLastScreen && (
          <button className="skip-button" onClick={handleSkip}>Skip</button>
        )}
      </div>
    </div>
   </div>
   </>
  );
}

export default OnboardingScreen;