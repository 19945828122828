import React from 'react';

function Screen2() {
  return (
    <div className="screen">
      <div className="image-container">
        <img src="https://www.foodiesfeed.com/wp-content/uploads/2023/06/pouring-honey-on-pancakes.jpg" alt="Screen 2" />
      </div>
      <h2>All you favourites</h2>
      <p>   Get your loved foods in one place<br />
      with over 1000s of buy one get one offers!
      </p>
    </div>
  );
}

export default Screen2;