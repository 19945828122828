

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock, faUser } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import logoImage from '../logo1.png';
import { FaUserCircle } from "react-icons/fa";
import { FaUserLock } from "react-icons/fa6";


function NavBarTop() {
  const [userid, setUserId] = useState("");
  const [greeting, setGreeting] = useState("");
  const [fname, setFirstName] = useState("");

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");

    if (user_id) {
      setUserId(user_id);
      fetchCustomer();
    }

    const today = new Date().getHours();

    if (today < 12) {
      setGreeting("Good Morning");
    } else if (today < 16) {
      setGreeting("Good Afternoon");
    } else if (today < 24) {
      setGreeting("Good Evening");
    }
  }, []);

  const fetchCustomer = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/public/api/customerinfo/${localStorage.getItem("user_id")}`)
      .then(({ data }) => {
        const { id, first_name, last_name } = data.customer;
        setFirstName(first_name);
        localStorage.setItem("customerid", id);
      })
      .catch(({ response }) => {
      });
  }

  return (
    <nav style={{
      position: "fixed",
      top: '15px',
      left: 0,
      right: 0,
      zIndex: 1000,
      padding: "10px 20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <div style={{
        position: "absolute",
        left: "20px",
        top: "50%",
        transform: "translateY(-50%)"
      }}>
      </div>
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1
      }}>
        <img 
          src={logoImage} 
          alt="AroodMe" 
          style={{ 
            height: "40px", 
            width: "auto"
          }} 
        />
      </div>
      <div style={{
        position: "absolute",
        right: "20px",
        top: "40%",
        transform: "translateY(-50%)"
      }}>
        <a href="#/profile" style={{ color: "#000", textDecoration: "none" }}>
          {userid ? (
            <FaUserCircle style={{ paddingTop:'5px'}} size={40} color="gray"/>
          ) : (
            <FaUserLock style={{ paddingTop:'5px'}} size={35} color="gray"/>
          )}
        </a>
      </div>
    </nav>
  );
}

export default NavBarTop;