import React, { useState, useEffect } from 'react';
import logo from '../logo.png'

import splashDesign from '../splashDesign.png'; 
import overlay from '../overlay.png'

function Splash() {
  return (
   
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: '#YourBackgroundColor', 
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999,
      overflow: 'hidden' 
    }}>
       <div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '280px',  
      height: '280px', 
      backgroundImage: `url(${overlay})`,
      backgroundSize: 'cover',
      backgroundPosition: 'top left',
      transform: 'rotate(-45deg) translate(-50%, -50%)', 
      transformOrigin: 'top left',
    }} />
      <img src={logo} alt="Logo" style={{
        width: '250px', 
        height: 'auto',
        position: 'relative',
        zIndex: 2 
      }} />
      <div style={{
        position: 'absolute',
        bottom: '-40%', 
        right: '-35%', 
        width: '80%',
        height: '80%', 
        background: `url(${splashDesign}) no-repeat center center`,
        backgroundSize: 'contain',
        transform: 'rotate(45deg)', 
        opacity: 0.7, 
        zIndex: 1
      }} />
    </div>
  );
}

export default Splash;