import React from 'react';

function Screen1() {
  return (
    <div className="screen">
      <div className="image-container">
        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjAMLedZRk2kc2Gg7NC0jRaHIjxa1-vf-b_A&s" alt="Screen 1" />
      </div>
      <h2>All Your favourites</h2>
      <p>  Get your loved foods in one place<br />
      with over 1000s of buy one get one offers!
      </p>
    </div>
  );
}

export default Screen1;