import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardTitle, Col, Container, Input, Row, Spinner, CardImg, CardText } from "reactstrap";
import axios from 'axios';
import NavBarBottom from "./NavBarBottom";
import NavBarTop from "./NavBarTop";
import MyDeals from "./deals/MyDeals";
import SignIn from "./auth/SignIn";
import { Link, useParams } from "react-router-dom";
import DealList from "./deals/DealList";
import PopularDeals from "./deals/PopularDeals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { IoTimeOutline } from "react-icons/io5";
import { AiOutlineDollar } from "react-icons/ai";
import { TiStarOutline } from "react-icons/ti";


function PopularCompanies({ searchTerm }) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const mainUrl = process.env.REACT_APP_MAIN_URL;
    const dineInAppUrl = process.env.REACT_APP_DINE_IN_APP_URL;

    const { id } = useParams()
    const [user, setUser] = useState();

    const [products, setProducts] = useState([]);
    const [title, setTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [comps, setCompanies] = useState([]);
    const [companies2, setCompanies2] = useState([

      {
          id: 2,
          name: "Hub Express",
          description: "Burgers-Italian-Sushi-Indian",
          image: "rest2.gif",
          rating: 4.7,
          priceRange: 3,
          deliveryTime: "20 min"
      },
      {
          id: 1,
          name: "The Hub 7",
          description: "JLT, Dubai",
          image: "rest1.gif",
          rating: 4.7,
          priceRange: 3,
          deliveryTime: "20 min"
      },
      {
          id: 3,
          name: "Lucca Stoppony",
          description: "Al Fahidi, Bur Dubai",
          image: "lucca.jpg",
          rating: 4.7,
          priceRange: 3,
          deliveryTime: "20 min"
      }
  ]);

  const normalizeString = (str) => {
    return str.toLowerCase().replace(/\s+/g, '');
};

const filteredCompanies = companies2.filter(company => {
    const normalizedSearchTerm = normalizeString(searchTerm);
    const normalizedName = normalizeString(company.name);
    const normalizedDescription = normalizeString(company.description);
    
    return normalizedName.includes(normalizedSearchTerm) || 
           normalizedDescription.includes(normalizedSearchTerm);
});

    const fetchCompanies = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/public/api/restaurants`).then(({ data }) => {
            setCompanies(data)
            setLoading(true);
        })
    }

    useEffect(() => {
        fetchCompanies()
    }, [])


    return (
        <>




            <Container style={{ padding: 0, paddingTop:'20px', maxWidth: "100%" }}>
                <div className="row title" style={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    marginLeft: '10px',
                    marginRight: '20px'
                }}>
                    <div className="col" >
                        <p  style={{fontSize:'18px', fontStyle:'normal' }}>Restaurants</p>
                    </div>
                    <div className="col text-end">
                        <Link to={`/comp/${companies2.id}`}  style={{ textDecoration: 'none',fontSize:'14px',color:'black' }}>See all &gt;</Link>
                    </div>
                </div>
            </Container>

            <Row>
                <div style={{ 
                    height: "auto", 
                    display: "flex", 
                    gap: '40px', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    flexDirection: 'column',
                    width: '95%',
                    padding: '20px 20px',
                    boxSizing: 'border-box',
                    paddingLeft:'20px',
                    marginLeft:'10px'
                }}>
                    {filteredCompanies.map(company => (
                        <Col xs="12" key={company.id} style={{ width: '100%', maxWidth: '400px' }}>
                            <Link style={{ textDecoration: "none" }} to={`/companydeals/${company.id}`}>
                                <div style={{ width: '100%', borderRadius: '8px', overflow: 'hidden', }}>
                                    <img src={company.image} alt={`${company.name} Image`} style={{ width: '100%', height: '150px', objectFit: 'cover' }} />
                                    <div style={{marginTop:'10px'}}>
                                        <h5 className="heading" style={{ margin: '0 0 10px 0' }}>{company.name}</h5>
                                        <p className="pc-card-text" style={{ margin: 0 }}>{company.description}</p>
                                    </div>
                                    <div className="d-flex align-items-center gap-4" style={{ marginTop:"10px"}}>
                                        <div className="d-flex align-items-center">
                                            <TiStarOutline color="#00A3FF" size={25} /><span style={{ marginLeft: "2px",color:'black',fontSize:'13px' }}>{company.rating}</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <AiOutlineDollar color="#00A3FF" size={25} /><span style={{ marginLeft: "2px",color:'black',fontSize:'13px' }}>{company.priceRange}</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <IoTimeOutline color="#00A3FF" size={25} /><span style={{ marginLeft: "2px",color:'black',fontSize:'13px' }}>{company.deliveryTime}</span>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                    ))}
                </div>
            </Row>
        

    {/* <Container style={{ padding: 0, paddingTop:'20px', maxWidth: "100%" }}>
  <div className="row title" style={{ 
    display: 'flex', 
    justifyContent: 'space-between', 
    marginLeft: '10px',
    marginRight: '20px'
  }}>
    <div className="col" >
      <p style={{fontSize:'20px'}}>Restaurants</p>
    </div>
    <div className="col text-end">
      <Link to="/all-categories" style={{ textDecoration: 'none',fontSize:'18px',color:'black' }}>See all &gt;</Link>
    </div>
  </div>
  </Container> */}

            {/* <Row>
                <div style={{ height: "auto", display: "flex",gap:'50px',alignItems:'center',justifyContent:'center' , flexDirection:'column'}}>

                    <Col xs="8" className="card-container" style={{width:'95%'}}>
                        <Link style={{ textDecoration: "none" }} to={`/companydeals/2`}>
                            <Card className=".my-card1">
                                <div className="image-overlay"></div>
                                <CardImg top src="rest2.gif" alt="Card Image" style={{height:'150px'}}  className="card-image" />
                                <CardBody className="overlay-text">
                                    <h5 className="heading">Hub Express</h5>
                                    <CardText className="pc-card-text">Al Fahidi, Bur Dubai</CardText>
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>
                    <Col xs="8" className="card-container" style={{width:'95%'}}>
                        <Link style={{ textDecoration: "none" }} to={`/companydeals/1`}>
                            <Card className=".my-card1">
                                <div className="image-overlay"></div>
                                <CardImg top src="rest1.gif" alt="Card Image" style={{height:'150px'}} className="card-image1" />
                                <CardBody className="overlay-text">
                                    <h5 className="heading">The Hub 7</h5>
                                    <CardText className="pc-card-text">JLT, Dubai</CardText>
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>
                    <Col xs="8" className="card-container" style={{width:'95%'}}>
                        <Link style={{ textDecoration: "none" }} to={`/companydeals/2`}>
                            <Card className="my-card">
                                <div className="image-overlay"></div>
                                <CardImg top src="lucca.jpg" alt="Card Image" style={{height:'150px'}} className="card-image"  />
                                <CardBody className="overlay-text">
                                    <h5 className="heading">Lucca Stoppony</h5>
                                    <CardText className="pc-card-text">Al Fahidi, Bur Dubai</CardText>
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>
                </div>
            </Row> */}


{/* <Row>
  <div style={{ 
    height: "auto", 
    display: "flex", 
    gap: '40px', 
    alignItems: 'center', 
    justifyContent: 'center', 
    flexDirection: 'column',
    width: '95%',
    padding: '20px 20px',
    boxSizing: 'border-box',
    paddingLeft:'20px',
    marginLeft:'10px'
  }}>
    <Col xs="12" style={{ width: '100%', maxWidth: '400px' }}>
      <Link style={{ textDecoration: "none" }} to={`/companydeals/2`}>
        <div style={{ width: '100%', borderRadius: '8px', overflow: 'hidden', }}>
          <img src="rest2.gif" alt="Card Image" style={{ width: '100%', height: '150px', objectFit: 'cover' }} />
          <div style={{marginTop:'10px'}}>
            <h5 className="heading" style={{ margin: '0 0 10px 0' }}>Hub Express</h5>
            <p className="pc-card-text" style={{ margin: 0 }}>Burgers-Italian-Sushi-Indian</p>
          </div>
          <div className="d-flex align-items-center gap-4" style={{ marginTop:"10px"}}>
  <div className="d-flex align-items-center">
    <TiStarOutline color="#00A3FF" size={30} /><span style={{ marginLeft: "2px",color:'black' }}>4.7</span>
  </div>
  <div className="d-flex align-items-center">
    <AiOutlineDollar color="#00A3FF" size={30} /><span style={{ marginLeft: "2px",color:'black' }}>3</span>
  </div>
  <div className="d-flex align-items-center">
    <IoTimeOutline color="#00A3FF" size={30} /><span style={{ marginLeft: "2px",color:'black' }}>20 min</span>
  </div>
</div>
        </div>
      </Link>
    </Col>
    <Col xs="12" style={{ width: '100%', maxWidth: '400px' }}>
      <Link style={{ textDecoration: "none" }} to={`/companydeals/1`}>
        <div className=".my-card1" style={{ width: '100%', borderRadius: '8px', overflow: 'hidden'}}>
          
          <img src="rest1.gif" alt="Card Image" style={{ width: '100%', height: '150px', objectFit: 'cover' }} />
          <div style={{marginTop:'10px'}}>
            <h5 className="heading" style={{ margin: '0 0 10px 0' }}>The Hub 7</h5>
            <p className="pc-card-text" style={{ margin: 0 }}>JLT, Dubai</p>
          </div>
                
  <div className="d-flex align-items-center gap-4" style={{marginTop:"10px"}}>
  <div className="d-flex align-items-center">
    <TiStarOutline color="#00A3FF" size={30} /><span style={{ marginLeft: "2px",color:'black' }}>4.7</span>
  </div>
  <div className="d-flex align-items-center">
    <AiOutlineDollar color="#00A3FF" size={30} /><span style={{ marginLeft: "2px",color:'black' }}>3</span>
  </div>
  <div className="d-flex align-items-center">
    <IoTimeOutline color="#00A3FF" size={30} /><span style={{ marginLeft: "2px",color:'black' }}>20 min</span>
  </div>
</div>
        </div>
      </Link>
    </Col>
    <Col xs="12" style={{ width: '100%', maxWidth: '400px' }}>
      <Link style={{ textDecoration: "none" }} to={`/companydeals/2`}>
        <div style={{ width: '100%', borderRadius: '8px', overflow: 'hidden' }}>
          <div></div>
          <img src="lucca.jpg" alt="lucca stoppony Image" style={{ width: '100%', height: '150px', objectFit: 'cover' }} />
          <div style={{marginTop:'10px'}}>
            <h5 className="heading" style={{ margin: '0 0 10px 0' }}>Lucca Stoppony</h5>
            <p className="pc-card-text" style={{ margin: 0 }}>Al Fahidi, Bur Dubai</p>
          </div>
 <div className="d-flex align-items-center gap-4" style={{ marginTop:"10px"}}>
  <div className="d-flex align-items-center">
    <TiStarOutline color="#00A3FF" size={30} /><span style={{ marginLeft: "2px",color:'black' }}>4.7</span>
  </div>
  <div className="d-flex align-items-center">
    <AiOutlineDollar color="#00A3FF" size={30} /><span style={{ marginLeft: "2px",color:'black' }}>3</span>
  </div>
  <div className="d-flex align-items-center">
    <IoTimeOutline color="#00A3FF" size={30} /><span style={{ marginLeft: "2px",color:'black' }}>20 min</span>
  </div>
</div>
        </div>
      </Link>
    </Col>
  </div>
</Row>
          */}

            {/* <div className="row title" >
                <div className="col-12">
                    <h6>Nearest Deals </h6>
                </div>
            </div>

            <Row>
                <div style={{ height: "auto", overflowY: "auto", display: "flex",flexDirection:'column',alignItems: 'center', gap:'50px' }}>
                    <Col xs="8" className="card-container" style={{width:'95%'}}>
                        <Link style={{ textDecoration: "none" }} to={`/companydeals/1`}>
                            <Card className="my-card">
                                <div className="image-overlay"></div>
                                <CardImg top src="rest1.gif" alt="Card Image" style={{height:'150px'}} className="card-image" />
                                <CardBody className="overlay-text">
                                    <h4 className="heading">The Hub 7</h4>
                                    <CardText className="pc-card-text">JLT, Dubai</CardText>
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>
                    <Col xs="8" className="card-container" style={{width:'95%'}}>
                        <Link style={{ textDecoration: "none" }} to={`/companydeals/2`}>
                            <Card className="my-card">
                                <div className="image-overlay"></div>
                                <CardImg top src="rest2.gif" alt="Card Image" style={{height:'150px'}} className="card-image" />
                                <CardBody className="overlay-text">
                                    <h4 className="heading">Hub Express</h4>
                                    <CardText className="pc-card-text">Al Fahidi, Bur Dubai</CardText>
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>
                    <Col xs="8" className="card-container" style={{width:'95%'}}>
                        <Link style={{ textDecoration: "none" }} to={`/companydeals/2`}>
                            <Card className="my-card">
                                <div className="image-overlay"></div>
                                <CardImg top src="lucca.jpg" alt="Card Image" style={{height:'150px'}} className="card-image"  />
                                <CardBody className="overlay-text">
                                    <h4 className="heading">Lucca Stoppony</h4>
                                    <CardText className="pc-card-text">Al Fahidi, Bur Dubai</CardText>
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>
                </div>
            </Row> */}

            {/* <div className="row">
                {
                    loading ?
                        comps.length > 0 && (
                            comps.map((row, key) => (
                                <div className="col-md-6 col-xs-12">
                                    <Link style={{ textDecoration: "none" }} to={`/companydeals/${row.res_id}`}>
                                        <div className="row shadow border-radius-15 mydeal-box" style={{ marginBottom: "20px" }}>
                                            <div className="col-3">
                                                <img className="border-radius-15" style={{ width: "100%", padding: "0px" }}
                                                    alt="Sample"
                                                    src={row.image}
                                                />
                                            </div>
                                            <div className="col-9">
                                                <h5 style={{ color: "#2B2B2B", marginTop: "10px" }}>{row.res_name}</h5>
                                                <p style={{ color: "#666", fontSize: "12px", padding: "0px" }}>{row.address}</p>
                                                <p style={{ color: "#FF1843", fontSize: "12px", padding: "0px" }}>Available Deals</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))) : <Spinner animation="border" />
                }
            </div> */}
        </>
    )
}
export default PopularCompanies;